<template>
  <div>
    <b-breadcrumb class="mb-1">
      <b-breadcrumb-item href="/">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
        Dashboard
      </b-breadcrumb-item>
      <b-breadcrumb-item v-on:click="goBack"
        >Factures récurrentes</b-breadcrumb-item
      >
      <b-breadcrumb-item active>Liste des Fichiers</b-breadcrumb-item>
    </b-breadcrumb>
    <b-card>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <!-- sorting  -->
          <b-form-group
            label="Filtrer par :"
            label-size="sm"
            label-align-sm="left"
            label-cols-sm="4"
            label-for="sortBySelect"
            class="mr-1 mb-md-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              >
              </b-form-select>
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
                <option :value="false">Croissant</option>
                <option :value="true">Décroissant</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>

          <!-- filter -->
          <b-form-group
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Recherche..."
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Effacer
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>
      <div>
        <p>
          Date du prochain envoi de la facture
          <span class="alert alert-warning" style="padding: 5px">
            {{ recurringinvoice.next_sending_time }}
          </span>
        </p>
      </div>
      <b-table
        style="height: 57vh"
        empty-text="Aucune facture disponible"
        empty-filtered-text="Aucune facture trouvée"
        show-empty
        head-variant="dark"
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        class="generated-card"
        sticky-header="5"
        striped
        :items="recurring_invoice_files"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            class="chart-dropdown"
            toggle-class="p-0"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="text-body cursor-pointer"
              />
            </template>

            <b-dropdown-item :href="data.item.filename" target="_blank">
              <feather-icon
                icon="EyeIcon"
                size="18"
                style="margin-right: 5px"
              />
              Voir la facture
            </b-dropdown-item>
            <b-dropdown-item target="_blank">
              <feather-icon
                icon="SendIcon"
                size="18"
                style="margin-right: 5px"
              />
              Envoyer au client
            </b-dropdown-item>
            <b-dropdown-item button @click="shareInvoiceShowModal(data.item)">
              <feather-icon
                icon="ShareIcon"
                size="18"
                style="margin-right: 5px"
              />
              Partager le devis
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

       <!-- SHARE INVOICE STARTING MODAAL -->
       <b-modal
        size="sm"
        hide-footer
        hide-header-close
        no-close-on-backdrop
        v-model="shareInvoiceModal"
        id="share-modal-center"
        centered
        :title="'Partager la Facture N° : ' + invoice.num"
      >
        <b-overlay :show="loading" rounded="sm">
          <p>Avec :</p>
          <validation-observer ref="shareInvoiceForm">
            <b-form>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :rules="'required'"
                  name="E-mail"
                >
                  <label for=""
                    >E-mail <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    placeholder="E-mail"
                    v-model="share.email"
                  ></b-form-input>
                  <small class="text-danger"> {{ errors[0] }} </small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider>
                  <label for="">E-mail en copie </label>
                  <b-form-input
                    placeholder="E-mail en copie"
                    v-model="share.cc"
                  ></b-form-input>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider>
                  <label for="">Sujet </label>
                  <b-form-input
                    placeholder="Sujet"
                    v-model="share.subject"
                  ></b-form-input>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <validation-provider
                  :rules="'required'"
                  #default="{ errors }"
                  name="Message"
                >
                  <b-textarea
                    placeholder="message..."
                    v-model="share.message"
                  ></b-textarea>
                  <small class="text-danger"> {{ errors[0] }} </small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </validation-observer>
          <div class="text-center">
            <b-button
              :disabled="sharing"
              size="sm"
              @click="shareInvoiceFunction(invoice)"
            >
              <b-spinner v-if="sharing" small type="grow"></b-spinner>
              Partager</b-button
            >
            <b-button
              class="ml-1"
              variant="outline-danger"
              size="sm"
              @click="(invoice = {}), (shareInvoiceModal = false)"
              >Fermer</b-button
            >
          </div>
        </b-overlay>
      </b-modal>
      <!-- SHARING INVOICE MODEL END -->

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Affichage"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BToast } from "bootstrap-vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import moment from "moment";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    BToast,
    ToastificationContent,
    FeatherIcon,
  },
  setup() {
    return {
      shareInvoiceModal: false,
      share: {
        invoice_num: null,
        email: "",
        cc: "",
        subject: "Partage de facture",
        message: "",
      },
      sharing: false,
      shareDefault: {
        invoice_num: null,
        email: "",
        cc: "",
        subject: "Partage de facture",
        message: "",
      },
      sharing: false,
      sendIsProgress: false,
      loading: false,
      isNumNull: false,
      text: "This will get copied!",
      showSendingModal: false,
      invoice: {
        num: "",
      },
      showModal: false,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "date",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],

      instanceCard: false,
      generatedCard: true,
      fields: [
        { key: "num", label: "Numéro", sortable: true },
        { key: "created_at", label: "Date d'envoi" },
        { key: "actions", thStyle: { width: "5%" } },
      ],
    };
  },
  computed: {
    ...mapState("tasks", [
      "invoices",
      "allInvoiceAndDevisDeleted",
      "recurring_invoice_files",
      "recurringinvoice",
    ]),
    ...mapGetters("tasks", ["vargetRecurringInvoice"]),

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  mounted() {
    this.fetchRecurringInvoiceFiles(this.$route.params.num);
    this.totalRows = this.invoices.length;

    if (this.recurring_invoice_files) {
      this.getRecurringInvoice(this.$route.params.num);
    }
  },

  watch: {
    invoices(val) {
      this.totalRows = val.length;
    },
  },

  methods: {
    ...mapActions("tasks", [
      "removeThisRecurringInvoice",
      "fetchRecurringInvoiceFiles",
      "getRecurringInvoice",
      "shareRecurringInvoiceToAnyOne",
    ]),

    shareInvoiceShowModal(invoice) {
      this.invoice = invoice;
      this.shareInvoiceModal = true;
    },

    async shareInvoiceFunction() {
      const isValid = await this.$refs.shareInvoiceForm.validate();
      if (!isValid) return;
      this.sharing = true;
      this.share.invoice_num = this.invoice.num;
      this.shareRecurringInvoiceToAnyOne(this.$formData(this.share))
        .then((res) => {
          this.sharing = false;
          this.shareInvoiceModal = false;
          this.share = this.shareDefault;
          this.$nextTick().then((response) => {
            this.$refs.shareInvoiceForm.reset();
          });
        })
        .catch((err) => {
          this.sharing = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erreur",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },

    goBack() {
      history.back();
    },

    // cancelInvoice(data) {
    //   this.removeThisRecurringInvoice(data.num).then((e) => {
    //     this.fetchRecurringInvoiceFiles(this.$route.params.num);
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: "Facture placée dans la corbeille",
    //         icon: "BellIcon",
    //         variant: "success",
    //       },
    //     });
    //   });
    // },

    resetForm() {
      this.showModal = false;
      this.invoice.num = null;
    },

    showInvoiceModal(data) {
      if (data.num != null) {
        this.isNumNull = true;
      } else {
        this.isNumNull = false;
      }
      this.showModal = true;
      this.invoice = data;
    },

    sendInvoice(data) {
      this.sendIsProgress = true;
      this.loading = true;
      this.sendValidatedInvoiceToCustomer(data.id)
        .then((e) => {
          this.allInvoices();
          this.showSendingModal = false;
          this.sendIsProgress = false;
          this.loading = false;
          this.showModal = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Facture envoyée avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.generated-card {
  max-height: 70vh;
}
</style>